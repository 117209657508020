import { IStackTokens, PrimaryButton, Stack } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useIsMobile from './useIsMobile';

/**
 * View component for the home view.
 *
 * @returns {ReactElement} Home View.
 */
export const HomeView: FunctionComponent = (): ReactElement => {
    /**
     * Basic stack layout tokens.
     */
    const stackTokens: IStackTokens = {
        childrenGap: 50,
        padding: 35,
    };

    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const imageContainerRef = useRef<HTMLDivElement>(null);

    const [imageContainerWidth, setImageContainerWidth] = useState<number>(300);

    useLayoutEffect(() => {
        const imageContainerWidth = imageContainerRef.current?.clientWidth;
        if (!imageContainerWidth) {
            return;
        }
        if (imageContainerWidth > 700) {
            setImageContainerWidth(700);
        } else {
            setImageContainerWidth(imageContainerWidth);
        }
    }, []);

    return (
        <Stack tokens={stackTokens} style={{ width: '100%' }} verticalAlign={isMobile ? 'center' : 'start'} horizontalAlign="center">
            <div ref={imageContainerRef} style={{ width: '100%', display: 'flex', justifyContent: 'center', marginRight: 32 }}>
                <img src="./Intro.svg" alt="Flower3" width={imageContainerWidth} style={{ objectFit: 'contain' }} />
            </div>
            <PrimaryButton style={{ marginBottom: 30 }} text="Ich habe eine Einladung" onClick={() => navigate('/einladung')} />
        </Stack>
    );
};
