import { IMessageBarStyles, IPalette, MessageBar, useTheme } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { useStoreActions, useStoreState } from '../store/Store';
import useIsMobile from '../views/useIsMobile';

const LayoutContainer = styled.div`
    display: flex;
    flex: 1;
`;

const LayoutContainerWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100vw;
`;

const ContentWrapper = styled.div<{ palette: IPalette; isMobile: boolean }>`
    display: flex;
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    background-color: ${(props) => props.palette.themeLight};
    padding: ${(props) => (props.isMobile ? '0' : '12px')};
    justify-content: center;
`;

const ContentContainer = styled.div<{ palette: IPalette; isMobile: boolean }>`
    display: flex;
    flex: 1;
    border-radius: 0px;
    background-color: ${(props) => props.palette.neutralLighterAlt};
    border-color: #222;
    border-width: ${(props) => (props.isMobile ? '0px' : '1px')};
    border-style: solid;
    overflow: auto;
    max-width: 1100px;
    padding: ${(props) => (props.isMobile ? '12px' : '35px')};
    overflow-x: hidden;
`;

export interface LayoutProps {
    /** Child component. */
    children?: ReactNode;
}

/**
 * The basic layout component.
 *
 * @param {LayoutProps} props Layout properties.
 * @returns {ReactElement} Basic layout.
 */
export const BasicLayout: FunctionComponent<LayoutProps> = (props: LayoutProps): ReactElement => {
    /**
     * Global fluent UI theme.
     */
    const theme = useTheme();

    /**
     * Store state of the global notification message bar.
     */
    const notificationModel = useStoreState((state) => state.GlobalNotificationModel.notification);

    /**
     * Store action to update the global notification message bar.
     */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    /**
     * Whether the global message bar is visible or not.
     */
    const [isMessageBarVisible, setIsMessageBarVisible] = useState<boolean>(false);

    const isMobile = useIsMobile();
    /**
     * Global message bar style.
     */
    const messageBarStyles: IMessageBarStyles = {
        root: {
            width: 'auto',
            minWidth: window.innerWidth < 400 ? window.innerWidth - 20 : 400,
            margin: 'auto',
            bottom: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            position: 'fixed',
            display: 'flex',
            zIndex: 99999,
        },
    };

    /**
     * Displays a new notification model and hides it after a timeout.
     */
    useEffect(() => {
        let messageBarTimeout: number | undefined;
        if (notificationModel) {
            if (messageBarTimeout) {
                clearTimeout(messageBarTimeout);
            }
            messageBarTimeout = window.setTimeout(() => {
                setIsMessageBarVisible(false);
                updateNotification(undefined);
            }, 5000 + Math.ceil(notificationModel.message.length / 120) * 1000);
            setIsMessageBarVisible(true);
        }
        return () => {
            if (messageBarTimeout) {
                clearTimeout(messageBarTimeout);
            }
        };
    }, [isMessageBarVisible, notificationModel, updateNotification]);

    return (
        <LayoutContainerWrapper>
            <LayoutContainer>
                <ContentWrapper palette={theme.palette} isMobile={isMobile}>
                    <ContentContainer isMobile={isMobile} palette={theme.palette}>
                        {props.children}
                    </ContentContainer>
                </ContentWrapper>
            </LayoutContainer>
            {notificationModel && isMessageBarVisible && (
                <MessageBar styles={messageBarStyles} messageBarType={notificationModel.type}>
                    {notificationModel.message}
                </MessageBar>
            )}
        </LayoutContainerWrapper>
    );
};
