import { PrimaryButton, Stack, Text, TextField, Theme, useTheme } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';

import { getFromApi, HttpError } from '../helper/ApiHelper';
import { InvitationData } from '../types/InvitationData';
import { InfoView } from './InfoView';

const pulseAnimation = keyframes`
         0% {
            transform: scale(0.8);
        }
        5% {
            transform: scale(0.9);
        }
        10% {
            transform: scale(0.8);
        }
        15% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(0.8);
        }
            `;
const HeartContainer = styled.div`
    animation: ${pulseAnimation} 2s linear infinite;
`;

const BusyContainer = styled.div<{ theme: Theme }>`
    justify-content: center;
    position: fixed;
    display: flex;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.palette.neutralLighterAlt};
    z-index: 200;
    align-items: center;
`;

/**
 * View component for the home view.
 *
 * @returns {ReactElement} Home View.
 */
export const AuthorizationView: FunctionComponent = (): ReactElement => {
    /**
     * Global fluent UI theme.
     */
    const theme = useTheme();
    const { codeParam } = useParams();

    const navigate = useNavigate();

    const codeStorageKey = 'invcode';

    const [code, setCode] = useState<string>(codeParam ?? '');
    const [status, setStatus] = useState<string>('');

    const [isBusy, setIsBusy] = useState<boolean>(true);

    const [invitationData, setInvitationData] = useState<InvitationData>();

    const isInvitationValid = invitationData != null;

    const fetchData = useCallback(
        async (code: string) => {
            setIsBusy(true);
            try {
                if (codeParam) {
                    const data = await getFromApi<InvitationData>(`guest/invitation/${code}`);
                    setInvitationData(data);
                    localStorage.setItem(codeStorageKey, code);
                }
                const item = localStorage.getItem(codeStorageKey);
                if (item != null) {
                    navigate(`/einladung/${item}`, { replace: true });
                } else {
                    localStorage.removeItem(codeStorageKey);
                    navigate(`/einladung/${code}`, { replace: true });
                }
            } catch (error) {
                const networkError = error as HttpError;
                if (networkError) {
                    if (networkError.status === 401 || networkError.status === 404) {
                        setStatus(`Falscher Code '${code}', bitte überprüfen und bestätigen.`);
                    } else {
                        setStatus(networkError.status + ' - ' + networkError.message);
                    }
                } else {
                    setStatus(JSON.stringify(error));
                    console.error(error);
                }
            }
            setTimeout(() => setIsBusy(false), 1000);
        },
        [codeParam, navigate]
    );

    useEffect(() => {
        const task = async () => {
            const lastAccessPass = localStorage.getItem(codeStorageKey);
            if (lastAccessPass) {
                setIsBusy(true);
                try {
                    const data = await getFromApi<InvitationData>(`guest/invitation/${lastAccessPass}`);
                    localStorage.setItem(codeStorageKey, lastAccessPass);
                    navigate(`/einladung/${lastAccessPass}`, { replace: true });
                    setInvitationData(data);
                } catch (error) {
                    console.error(error);
                }
                setTimeout(() => setIsBusy(false), 1000);
            } else {
                setIsBusy(false);
            }
        };
        if (!codeParam) {
            task();
        }
    }, [codeParam, navigate]);

    useEffect(() => {
        const item = localStorage.getItem(codeStorageKey);
        if (codeParam) {
            fetchData(codeParam);
        } else if (item != null) {
            fetchData(item);
        } else {
            setIsBusy(false);
        }
    }, [codeParam, fetchData]);

    return (
        <>
            {isBusy && (
                <BusyContainer theme={theme}>
                    <HeartContainer>
                        <img src="./busy-indicator.png" alt="♥️" height={300} />
                    </HeartContainer>
                </BusyContainer>
            )}
            {!isInvitationValid && (
                <Stack verticalAlign="center" horizontalAlign="center" style={{ width: '100%' }}>
                    <Stack horizontal verticalAlign="end" tokens={{ childrenGap: '10' }}>
                        <TextField
                            label="Code"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    fetchData(code);
                                }
                            }}
                            disabled={isBusy}
                            value={code}
                            onChange={(_, newValue) => setCode(newValue || '')}
                        />
                        <PrimaryButton disabled={isBusy} onClick={() => fetchData(code)} text="Prüfen" />
                    </Stack>
                </Stack>
            )}
            {isInvitationValid ? <InfoView invitationData={invitationData} /> : <Text variant={'medium'}>{status}</Text>}
        </>
    );
};
