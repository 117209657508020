import React, { FunctionComponent, ReactElement } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '../layout/BasicLayout';
import { AuthorizationView } from '../views/AuthorizationView';
import { HomeView } from '../views/HomeView';

/**
 * The routes component.
 *
 * @returns {ReactElement} Router.
 */
export const AppRoutes: FunctionComponent = (): ReactElement => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/einladung/:codeParam"
                    element={
                        <BasicLayout>
                            <AuthorizationView />
                        </BasicLayout>
                    }
                />
                <Route
                    path="/einladung"
                    element={
                        <BasicLayout>
                            <AuthorizationView />
                        </BasicLayout>
                    }
                />
                <Route
                    path="/"
                    element={
                        <BasicLayout>
                            <HomeView />
                        </BasicLayout>
                    }
                />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};
