import { DefaultButton, IButtonStyles, IconButton, useTheme } from '@fluentui/react';
import React, { CSSProperties, useEffect, useState } from 'react';

import useIsMobile from './useIsMobile';
import { useScroll } from './useScroll';

const backgroundColor = '#000A';

export const Menu: React.FunctionComponent = () => {
    const theme = useTheme();

    const isMobile = useIsMobile();

    const rootNavButtonStyle = (isMobile: boolean): IButtonStyles => ({
        root: {
            border: 'none',
            borderRadius: 0,
            backgroundColor: backgroundColor,
            color: theme.palette.neutralPrimaryAlt,
            height: 45,
            minWidth: 58,
        },
        rootHovered: {
            backgroundColor: '#000C',
            color: '#EEE',
        },
        rootPressed: {
            backgroundColor: '#000D',
            color: '#FFF',
        },
        label: {
            fontSize: 16,
            fontWeight: 400,
        },
    });

    const navButtonStyle = (visible: boolean, isMobile: boolean, isLast?: boolean, isFirst?: boolean): IButtonStyles => ({
        root: {
            visibility: !visible ? 'hidden' : 'visible',
            border: 'none',
            borderRadius: 0,
            backgroundColor: isMobile ? '#000E' : backgroundColor,
            color: '#b7b7b7',
            height: isMobile ? '100%' : 70,
            minWidth: isMobile ? '100%' : 58,
            paddingLeft: isMobile ? 90 : 50,
            paddingBottom: isMobile && isLast ? 100 : 'unset',
            paddingTop: isMobile && isFirst ? 100 : 'unset',
        },
        rootHovered: {
            backgroundColor: isMobile ? '#000F' : '#000C',
            color: '#FFF',
        },
        rootPressed: {
            backgroundColor: isMobile ? '#000F' : '#000D',
            color: '#b7b7b7',
        },
        label: {
            fontSize: isMobile ? 48 : 19,
            fontWeight: 400,
            textAlign: 'start',
            fontFamily: isMobile ? 'Gistesy' : 'Montserrat',
        },
    });

    const [isExpanded, setExpanded] = useState<boolean>(false);

    const containerStyle: CSSProperties = {
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 100,
        marginTop: 0,
        marginRight: 5,
        transition: 'all 0.5s',
        overflow: 'hidden',
        borderRadius: '0 0 0 10px',
    };

    const regularStyle: CSSProperties = {
        marginLeft: 'auto',
        width: '100%',
    };
    const mobileStyle: CSSProperties = {
        display: isExpanded ? 'flex' : 'none',
        flexDirection: 'column',
        width: '100vw',
        marginTop: 0,
        height: '100vh',
    };

    const scrollTo = (elementId: string): void => {
        setExpanded(false);
        const element = document.getElementById(elementId);
        const offset = -120;
        if (element) {
            console.log(element.offsetTop + offset);

            window.scrollTo({ top: element.offsetTop + offset, behavior: 'smooth' });

            //element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const { scrollDirection } = useScroll();

    useEffect(() => {
        setExpanded(false);
    }, [scrollDirection]);

    const styleActive: CSSProperties = {
        ...containerStyle,
        visibility: 'visible',
    };
    const styleHidden: CSSProperties = {
        ...containerStyle,
        visibility: 'hidden',
        transition: 'all 0.5s',
        transform: 'translateY(-100%)',
    };

    return (
        <div style={scrollDirection === 'down' ? styleActive : styleHidden}>
            <div style={isMobile ? mobileStyle : regularStyle}>
                <DefaultButton
                    text="Ablauf"
                    hidden={!isExpanded}
                    styles={navButtonStyle(isExpanded || !isMobile, isMobile, false, true)}
                    onClick={() => scrollTo('timeline')}
                    onTouchEnd={() => scrollTo('timeline')}
                />
                <DefaultButton
                    text="Orte"
                    hidden={!isExpanded}
                    styles={navButtonStyle(isExpanded || !isMobile, isMobile)}
                    onClick={() => scrollTo('orte')}
                    onTouchEnd={() => scrollTo('orte')}
                />
                <DefaultButton
                    text="Unterkünfte"
                    hidden={!isExpanded}
                    styles={navButtonStyle(isExpanded || !isMobile, isMobile)}
                    onClick={() => scrollTo('unterkuenfte')}
                    onTouchEnd={() => scrollTo('unterkuenfte')}
                />
                <DefaultButton
                    hidden={!isExpanded}
                    text="Kontakt"
                    styles={navButtonStyle(isExpanded || !isMobile, isMobile)}
                    onClick={() => scrollTo('ansprechpartner')}
                    onTouchEnd={() => scrollTo('ansprechpartner')}
                />
                <DefaultButton
                    hidden={!isExpanded}
                    text="Begleitung"
                    styles={navButtonStyle(isExpanded || !isMobile, isMobile, true, false)}
                    onClick={() => scrollTo('teilnehmer')}
                    onTouchEnd={() => scrollTo('teilnehmer')}
                />
            </div>
            {isMobile && (
                <IconButton styles={rootNavButtonStyle(isMobile)} onClick={() => setExpanded(true)} iconProps={{ iconName: 'CollapseMenu' }} title={'Menü'} ariaLabel="Menu" />
            )}
        </div>
    );
};
