import { ComponentsStyles, IFontStyles, IPalette, IRawStyle, ISemanticColors } from '@fluentui/react';

/**
 * The color palette for the theme.
 */
export const Palette: Partial<IPalette> = {
    black: '#000000',
    blackTranslucent40: '#00000029',
    neutralDark: '#151515',
    neutralLight: '#eaeaea',
    neutralLighter: '#f4f4f4',
    neutralLighterAlt: '#f8f8f8',
    neutralPrimary: '#000000',
    neutralPrimaryAlt: '#2f2f2f',
    neutralQuaternary: '#d0d0d0',
    neutralQuaternaryAlt: '#dadada',
    neutralSecondary: '#373737',
    neutralSecondaryAlt: '',
    neutralTertiary: '#595959',
    neutralTertiaryAlt: '#c8c6c4',
    red: '#F57F6B',
    themeDark: '#006a93',
    themeDarkAlt: '#007dae',
    themeDarker: '#004e6d',
    themeLight: '#c7e0f4',
    themeLighter: '#deecf9',
    themeLighterAlt: '#eff6fc',
    themePrimary: '#008bc1',
    themeSecondary: '#1897c9',
    themeTertiary: '#57b5da',
    white: '#ffffff',
};

/**
 * The color palette for the theme.
 */
export const DarkPalette: Partial<IPalette> = {
    black: '#ffffff',
    blackTranslucent40: '#ffffff15',
    neutralDark: '#c4bfb7',
    neutralLight: '#333333',
    neutralLighter: '#181818', // disabled input background
    neutralPrimary: '#CDCFD0', // font
    neutralPrimaryAlt: '#707070',
    neutralQuaternaryAlt: '#0b0c0d',
    neutralQuaternary: '#0a0c0c',
    neutralSecondary: '#8c8c8c',
    neutralTertiary: '#CDCFD0', // disabled font
    neutralTertiaryAlt: '#0a0b0c',
    red: '#B23030',
    themeDarkAlt: '#6a7049',
    themeDark: '#595f3e',
    themeDarker: '#42462d',
    themeLight: '#000',
    themeLighter: '#deecf9',
    themeLighterAlt: '#eff6fc',
    themePrimary: '#777E52',
    themeSecondary: '#858d61',
    themeTertiary: '#abb18c',
    neutralLighterAlt: '#0D0F10', // letter body
    white: '#181818', // background
};

/**
 * The semantic colors.
 */
export const SemanticColors: Partial<ISemanticColors> = {};

/**
 * The semantic colors for the dark theme.
 */
export const DarkSemanticColors: Partial<ISemanticColors> = {
    successBackground: '#42462d',
    successIcon: '#eff6fc',
    infoBackground: '#BABABA',
    infoIcon: '#222222',
    warningBackground: '#D3BE7A',
    warningIcon: '#333333',
    severeWarningBackground: '#D39F7A',
    errorBackground: '#D3887A',
};

/**
 * The fonts for the theme.
 */
export const Fonts: Partial<IFontStyles> = {
    // Control Icons
    small: {
        fontSize: '16px',
        fontWeight: 'regular',
    },
    // Nav Menus, Labels
    medium: {
        fontSize: '20px',
        fontWeight: '300',
    },
    // Icons
    mediumPlus: {
        fontSize: '20px',
        fontWeight: '500',
    },
    // Modal Headlines
    large: {
        fontSize: '24px',
        fontWeight: '500',
    },
    // Main Headline
    xxLarge: {
        fontSize: '60px',
        fontWeight: 'regular',
    },
    // Sub Headlines
    xLarge: {
        fontSize: '50px',
        fontWeight: 'regular',
    },
};

/**
 * The default font style to use for theme.
 */
export const DefaultFontStyle: IRawStyle = {
    fontFamily: 'Montserrat, Arial, Helvetica, sans-serif',
    fontWeight: 'regular',
};

/**
 * Default styles for fluent ui components.
 *
 * @param {IPalette} palette The theme color palette.
 * @returns {ComponentsStyles} The default component styles.
 */
export const DefaultComponentStyles = (palette: Partial<IPalette>): ComponentsStyles => {
    return {
        MessageBar: {
            styles: {
                root: {
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'center',
                },
                innerText: {
                    paddingTop: 2,
                    paddingLeft: 3,
                    color: '#eff6fc'
                },
            },
        },
        PrimaryButton: {
            styles: {
                root: {
                    borderRadius: 2,
                    color: palette.themeSecondary,
                    borderColor: palette.themeSecondary,
                    backgroundColor: 'transparent',
                },
                rootHovered: {
                    color: palette.themePrimary,
                    backgroundColor: palette.themePrimary + '20',
                },
                rootPressed: {
                    color: palette.themeDark,
                    backgroundColor: palette.themePrimary + '40',
                    borderColor: palette.themeDark,
                },
            },
        },
        Label: {
            styles: {
                root: {
                    fontSize: 14,
                },
            },
        },
    };
};
