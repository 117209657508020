import { PartialTheme, ThemeProvider } from '@fluentui/react';
import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components/macro';

import { AppRoutes } from './navigation/AppRoutes';
import { DarkPalette, DarkSemanticColors, DefaultComponentStyles, DefaultFontStyle, Fonts } from './theme/Theme';

const AppContainer = styled.div`
    display: flex;
    min-height: 100vh;
    height: 100vh;
    min-width: 100%;
    width: 100%;
`;

/**
 * The application component.
 *
 * @returns {ReactElement | null} Main App component or NULL if a dependency is missing.
 */
const App: FunctionComponent = (): ReactElement | null => {
    // Initialize the fluent ui theme.
    const theme: PartialTheme = {
        palette: DarkPalette,
        defaultFontStyle: DefaultFontStyle,
        components: DefaultComponentStyles(DarkPalette),
        fonts: Fonts,
        isInverted: true,
        semanticColors: DarkSemanticColors,
    };

    return (
        <ThemeProvider theme={theme}>
            <AppContainer>
                <AppRoutes />
            </AppContainer>
        </ThemeProvider>
    );
};

export default App;
