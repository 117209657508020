/**
 * Custom error class for HTTP errors.
 */
export class HttpError extends Error {
    constructor(status: number, message: string) {
        super(message);
        this.status = status;
        console.error(`HTTP Request (${status}): "${message}"`);
    }
    status = -1;
}

/**
 * Retrieves data from the API as a JSON object.
 *
 * @template T
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @param {boolean} anonymous Whether the request shall be anonymous, therefore without a bearer token.
 * @returns {Promise<T>} The result as an object.
 */
export const getFromApi = async <T>(relativePath: string, abortSignal?: AbortSignal, anonymous?: boolean): Promise<T> => {
    return fetchApi(relativePath, 'GET', undefined, abortSignal, anonymous).then(async (response) => {
        if (response.ok) {
            return response.json() as Promise<T>;
        }
        const responseText = await response.text();
        let errorMessage = '';
        if (responseText) {
            errorMessage = JSON.parse(responseText);
        }
        throw new HttpError(response.status, errorMessage);
    });
};

/**
 * Posts a request to the backend API.
 *
 * @template T
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {any} body The object to put in the body of the request
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @param {boolean} anonymous Whether the request shall be anonymous, therefore without a bearer token.
 * @returns {Promise<T>} The answer as an object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postToApi = async <T>(relativePath: string, body?: any, abortSignal?: AbortSignal, anonymous?: boolean): Promise<T> => {
    return fetchApi(relativePath, 'POST', body, abortSignal, anonymous).then(async (response) => {
        if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                return response.json() as Promise<T>;
            }
            return {} as Promise<T>;
        }
        const responseText = await response.text();
        let errorMessage = '';
        if (responseText) {
            const error = JSON.parse(responseText);
            if (typeof error === 'object') {
                if (error['detail'] !== undefined) {
                    errorMessage = error['detail'];
                } else {
                    console.warn(responseText);
                }
            }
        }
        throw new HttpError(response.status, errorMessage);
    });
};

/**
 * Deletes an object from the api.
 *
 * @template T
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @returns {Promise<T>} Returns the result if any.
 */
export const deleteFromApi = async <T>(relativePath: string, abortSignal?: AbortSignal): Promise<T> => {
    return fetchApi(relativePath, 'DELETE', undefined, abortSignal).then(async (response) => {
        if (response.ok) {
            return response.json();
        }
        const errorMessage = JSON.parse(await response.text());
        throw new HttpError(response.status, errorMessage);
    });
};

/**
 * Calls the backend API.
 *
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {string} method The HTTP verb to use.
 * @param {any} body The object to put in the body of the request.
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @param {boolean} anonymous Whether the request shall be anonymous, therefore without a bearer token.
 * @returns {Promise<any>} Returns the result if any.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchApi = async (relativePath: string, method: string, body?: any, abortSignal?: AbortSignal, anonymous?: boolean): Promise<any> => {
    // Local: https://localhost:44301/api
    // Azure: https://api-jw-wedding.azurewebsites.net/api
    return await fetchWithHeader(`${'https://api-jw-wedding.azurewebsites.net/api'}/${relativePath}`, method, null, body, abortSignal);
};

/**
 * Fetches the API with required default headers.
 *
 * @param {string} url The relative path to the endpoint to query.
 * @param {string} method The HTTP verb to use.
 * @param {string[]} scopes The scopes to request for authorization.
 * @param {any} body The object to put in the body of the request.
 * @param {AbortSignal} abortSignal An abort signal to cancel the request.
 * @returns {Promise<any>} Returns the result if any.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchWithHeader = async (url: string, method: string, scopes: string[] | null, body?: any, abortSignal?: AbortSignal): Promise<any> => {
    const headers = new Headers();
    headers.append('Time-Offset', `${-new Date().getTimezoneOffset()}`);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Accept-Language', 'de-de');
    headers.append('Culture', navigator.language);
    const serializedBody = body !== undefined ? (JSON.stringify(body) as BodyInit) : null;
    return fetch(url, {
        method,
        headers: headers,
        signal: abortSignal,
        body: serializedBody,
    }).catch((error) => {
        throw error;
    });
};
