import '@leenguyen/react-flip-clock-countdown/dist/index.css';

import { IStackTokens, ITextStyles, MessageBarType, PrimaryButton, Stack, Text, TextField, useTheme } from '@fluentui/react';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { postToApi } from '../helper/ApiHelper';
import { useStoreActions } from '../store/Store';
import { Guest } from '../types/Guest';
import { InvitationData } from '../types/InvitationData';
import { Reply } from '../types/Reply';
import { GuestList } from './GuestList';
import { darkStyles } from './MapsStyle';
import { Menu } from './Menu';
import useIsMobile from './useIsMobile';

export interface Props {
    invitationData: InvitationData;
}

const ceremonyPos = {
    lng: 11.623858423513795,
    lat: 52.115183131292554,
};

const partyPos = {
    lng: 11.667882246677618,
    lat: 52.14150030836942,
};

const RulesTile = styled.div`
    background-color: #262626;
    border-radius: 7px;
    padding: 15px;
`;

const BaseTile = styled.div<{ isMobile: boolean }>`
    background-color: #262626;
    border-radius: 7px;
    padding: 15px;
    max-width: ${(props) => (props.isMobile ? '300px' : 'unset')};
`;

/**
 * View component for the home view.
 *
 * @param {Props} props Invitation data.
 * @returns {ReactElement} Home View.
 */
export const InfoView: FunctionComponent<Props> = (props: Props): ReactElement => {
    const theme = useTheme();

    const [isBusy, setIsBusy] = useState<boolean>(false);

    const mainImageRef = useRef<HTMLDivElement>(null);

    const pageRef = useRef<HTMLDivElement>(null);

    const [mainImageWidth, setMainImageWidth] = useState<number>(0);
    const [pageWidth, setPageWidth] = useState<number>(0);

    const isMobile = useIsMobile();

    const [line1, setLine1] = useState<string>(props.invitationData.invitation.address?.line1 ?? '');
    const [city, setCity] = useState<string>(props.invitationData.invitation.address?.city ?? '');
    const [postalCode, setPostalCode] = useState<string>(props.invitationData.invitation.address?.postalCode ?? '');
    /**
     * Store action to update the global notification message bar.
     */
    const updateNotification = useStoreActions((actions) => actions.GlobalNotificationModel.updateNotification);

    const [guests, setGuests] = useState<Guest[]>(props.invitationData.guests);

    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
        lng: 11.646578290171233,
        lat: 52.128232654629805,
    });

    const [mapZoom, setMapZoom] = useState<number>(13);

    useEffect(() => {
        let hasChanged = false;
        if (line1 !== (props.invitationData.invitation.address?.line1 ?? '')) {
            hasChanged = true;
        }
        if (city !== (props.invitationData.invitation.address?.city ?? '')) {
            hasChanged = true;
        }
        if (postalCode !== (props.invitationData.invitation.address?.postalCode ?? '')) {
            hasChanged = true;
        }
        if (guests !== props.invitationData.guests) {
            hasChanged = true;
        }
        if (hasChanged) {
            setHasChanges(true);
        }
    }, [
        city,
        guests,
        line1,
        postalCode,
        props.invitationData.guests,
        props.invitationData.invitation.address?.city,
        props.invitationData.invitation.address?.line1,
        props.invitationData.invitation.address?.postalCode,
    ]);

    const answerInvitation = useCallback(async () => {
        setIsBusy(true);
        const reply: Reply = {
            guests: [...guests],
            address: { city: city, id: props.invitationData.invitation.address?.id ?? 0, line1: line1, postalCode: postalCode },
        };
        try {
            await postToApi(`guest/answer/${props.invitationData.invitation.code}`, reply);
            updateNotification({ message: 'Gespeichert', type: MessageBarType.success });
            setHasChanges(false);
        } catch (error) {
            updateNotification({ message: 'Fehler', type: MessageBarType.error });
            console.error('Sending Response', error);
        }
        setIsBusy(false);
    }, [city, guests, line1, postalCode, props.invitationData.invitation.address?.id, props.invitationData.invitation.code, updateNotification]);

    const minimalSpacingToken: IStackTokens = {
        childrenGap: isMobile ? 5 : 10,
    };

    const titleStyle: ITextStyles = {
        root: {
            fontFamily: 'Gistesy',
            color: theme.palette.themeLighterAlt,
        },
    };

    const cursiveStyle: ITextStyles = {
        root: {
            fontFamily: 'Gistesy',
            textAlign: 'center',
        },
    };

    const rootContainerTokens: IStackTokens = {
        childrenGap: 200,
    };

    const { isLoaded: isMapLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBRygaliEvou1DHYJM7DnRIZJrYPGfHMWA',
    });

    const onLoad = useCallback((map: google.maps.Map) => {
        const bounds = new window.google.maps.LatLngBounds(partyPos);
        map.fitBounds(bounds);
    }, []);

    const isAssignedMultiple = props.invitationData.guests.length > 1;

    const linkColor = '#b9c18e';

    useLayoutEffect(() => {
        if (!isMapLoaded) {
            return;
        }
        const mainImageWidth = mainImageRef.current?.clientWidth ?? 0;
        const pageWidth = pageRef.current?.clientWidth ?? 0;
        setMainImageWidth(mainImageWidth);
        setPageWidth(pageWidth);
        console.log(mainImageWidth, pageWidth);
    }, [isMapLoaded]);

    if (!isMapLoaded) {
        return <></>;
    }

    return (
        <div ref={pageRef}>
            <Menu />
            <Stack tokens={rootContainerTokens} style={{ width: '100%', marginTop: 30 }} horizontalAlign="center">
                <Stack tokens={{ childrenGap: 60 }} horizontalAlign="center">
                    <Text styles={cursiveStyle} style={{ fontSize: isMobile ? 60 : 80, margin: isMobile ? 20 : 0 }} variant={'xxLarge'}>
                        {'Willkommen zu unserer Hochzeit'}
                    </Text>
                    <div ref={mainImageRef} style={{ width: '100%' }}>
                        <img src="./kerzenlicht_3.png" alt="Standesamt" width={mainImageWidth} style={{ objectFit: 'contain' }} />
                    </div>
                    <Stack horizontalAlign="center" style={{ marginBottom: 20 }}>
                        <Text styles={titleStyle} variant={'xxLarge'} style={{ fontSize: isMobile ? 60 : 75 }}>
                            {props.invitationData.weddingInformation.name}
                        </Text>
                    </Stack>
                    <Stack tokens={minimalSpacingToken} horizontalAlign="center">
                        <Text variant={'mediumPlus'} style={{ textAlign: 'center' }}>
                            {`Am ${props.invitationData.weddingInformation.date} geben wir uns das Ja`}&#8209;{`Wort`}
                        </Text>
                        <div style={{ marginTop: 40, width: 343 }}>
                            <FlipClockCountdown
                                to={new Date(props.invitationData.weddingInformation.dateCountdown).getTime()}
                                labels={['Tage', 'Stunden', 'Minuten', 'Sekunden']}
                                labelStyle={{ fontSize: 14, fontWeight: 300, textTransform: 'uppercase' }}
                                digitBlockStyle={{ width: 40, height: 60, fontSize: 30 }}
                                dividerStyle={{ color: 'transparent', height: 1 }}
                                separatorStyle={{ color: theme.palette.neutralPrimary, size: '6px' }}
                                renderMap={[true, true, true, false]}
                            />
                        </div>
                        <Stack tokens={minimalSpacingToken} horizontalAlign="center">
                            <img src="./flower1.svg" alt="flower" width={isMobile ? 300 : 450} style={{ marginTop: 22 }} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack tokens={minimalSpacingToken} style={{ paddingLeft: isMobile ? 23 : 80, paddingRight: isMobile ? 23 : 80 }}>
                    <Text styles={{ root: { marginBottom: 10 } }} variant={'mediumPlus'}>{`Hallo ${
                        props.invitationData.guests.map((g) => g.firstName).join(' und ') ?? 'Gast'
                    },`}</Text>
                    <Text styles={{ root: { textAlign: 'justify', lineHeight: 30 } }} variant={'medium'}>
                        {isAssignedMultiple ? props.invitationData.weddingInformation.mainTextMulti1 : props.invitationData.weddingInformation.mainTextSingle1}
                    </Text>
                    <Text styles={{ root: { textAlign: 'justify', lineHeight: 30 } }} variant={'medium'}>
                        {isAssignedMultiple ? props.invitationData.weddingInformation.mainTextMulti2 : props.invitationData.weddingInformation.mainTextSingle2}
                    </Text>
                    <Text styles={{ root: { textAlign: 'justify', lineHeight: 30 } }} variant={'medium'}>
                        {isAssignedMultiple ? props.invitationData.weddingInformation.mainTextMulti3 : props.invitationData.weddingInformation.mainTextSingle3}
                    </Text>
                    <img src="./unterschrift.png" alt="Jens & Michelle" width={250} style={{ marginTop: 40, marginLeft: 40 }} />
                </Stack>
                <Stack tokens={{ childrenGap: 60 }}>
                    <Text styles={cursiveStyle} variant={'xxLarge'}>
                        {'Unsere Geschichte'}
                    </Text>
                    <BaseTile isMobile={isMobile} style={{ minWidth: isMobile ? 'unset' : 590 }}>
                        <Stack horizontal={!isMobile} reversed={isMobile} verticalAlign="center" horizontalAlign="center" wrap tokens={{ childrenGap: isMobile ? 30 : 50 }}>
                            <Stack horizontalAlign="center" style={{ minWidth: 270 }}>
                                <Text styles={cursiveStyle} style={{ fontSize: 56 }} variant={'mediumPlus'}>
                                    {'Verliebt'}
                                </Text>
                                <Text variant={'medium'} style={{ marginBottom: isMobile ? 14 : 'unset' }}>
                                    {'03.10.2018'}
                                </Text>
                            </Stack>
                            <img src="./verliebt.png" alt="Standesamt" height={300} style={{ objectFit: 'contain', borderRadius: 5, objectPosition: '0px' }} />
                        </Stack>
                    </BaseTile>
                    <BaseTile isMobile={isMobile} style={{ minWidth: isMobile ? 'unset' : 300 }}>
                        <Stack horizontal={!isMobile} verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: isMobile ? 30 : 50 }}>
                            <img src="./verlobt.png" alt="Verlobt" height={300} style={{ objectFit: 'contain', borderRadius: 5, objectPosition: '0px' }} />
                            <Stack horizontalAlign="center" style={{ minWidth: 270 }}>
                                <Text styles={cursiveStyle} style={{ fontSize: 56 }} variant={'mediumPlus'}>
                                    {'Verlobt'}
                                </Text>
                                <Text variant={'medium'} style={{ marginBottom: isMobile ? 14 : 'unset' }}>
                                    {'13.02.2022'}
                                </Text>
                            </Stack>
                        </Stack>
                    </BaseTile>
                    <BaseTile isMobile={isMobile} style={{ minWidth: isMobile ? 'unset' : 590 }}>
                        <Stack horizontal={!isMobile} reversed={isMobile} verticalAlign="center" horizontalAlign="center" wrap tokens={{ childrenGap: isMobile ? 30 : 50 }}>
                            <Stack horizontalAlign="center" style={{ minWidth: 270 }}>
                                <Text styles={cursiveStyle} style={{ fontSize: 56 }} variant={'mediumPlus'}>
                                    {'Verheiratet'}
                                </Text>
                                <Text variant={'medium'} style={{ marginBottom: isMobile ? 14 : 'unset' }}>
                                    {props.invitationData.weddingInformation.date}
                                </Text>
                            </Stack>
                            <div style={{ padding: 20 }}>
                                <img src="./verheiratet_2.png" alt="Verheiratet" height={260} style={{ objectFit: 'contain', borderRadius: 5, objectPosition: 'bottom 10px' }} />
                            </div>
                        </Stack>
                    </BaseTile>
                </Stack>
                <div id="timeline">
                    <Stack tokens={{ childrenGap: 70 }} horizontalAlign="center">
                        <Text styles={cursiveStyle} variant={'xxLarge'}>
                            {'Ablauf'}
                        </Text>
                        <BaseTile isMobile={isMobile}>
                            <Stack tokens={{ childrenGap: 70 }} horizontalAlign="center" style={{ padding: 40 }}>
                                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: isMobile ? 8 : 20 }} wrap>
                                    <img src="./rings.svg" alt="Ringe" height={115} style={{ objectFit: 'contain', minWidth: 200 }} />
                                    <Stack horizontalAlign={isMobile ? 'center' : 'start'} verticalAlign="center" style={{ minWidth: 250 }}>
                                        <Text variant={'large'}>{'13:45 Uhr'}</Text>
                                        <Text variant={'mediumPlus'}>{'Trauzeremonie'}</Text>
                                        <Text variant={'medium'}>{'Standesamt Magdeburg'}</Text>
                                        <Text variant={'medium'}>{'Humboldtstraße 11'}</Text>
                                        <Text variant={'medium'}>{'39112 Magdeburg'}</Text>
                                    </Stack>
                                </Stack>
                                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: isMobile ? 21 : 20 }} wrap>
                                    <img src="./glasses.svg" alt="Sekt" height={115} style={{ objectFit: 'contain', minWidth: 200 }} />
                                    <Stack horizontalAlign={isMobile ? 'center' : 'start'} verticalAlign="center" style={{ minWidth: 250 }}>
                                        <Text variant={'large'}>{'14:30 Uhr'}</Text>
                                        <Text variant={'mediumPlus'}>{'Sektempfang'}</Text>
                                        <Text variant={'mediumPlus'}>{'Orangerie Magdeburg'}</Text>
                                        <Text variant={'medium'}>{'Herrenkrugstraße 140'}</Text>
                                        <Text variant={'medium'}>{'39114 Magdeburg'}</Text>
                                    </Stack>
                                </Stack>
                                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: isMobile ? 13 : 20 }} wrap>
                                    <img src="./music.svg" alt="Musik" height={115} style={{ objectFit: 'contain', minWidth: 200 }} />
                                    <Stack horizontalAlign={isMobile ? 'center' : 'start'} verticalAlign="center" style={{ minWidth: 250, marginBottom: 15 }}>
                                        <Text variant={'large'}>{'21:00 Uhr'}</Text>
                                        <Text variant={'medium'}>{'Party'}</Text>
                                        <Text variant={'mediumPlus'}>{'Orangerie Magdeburg'}</Text>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </BaseTile>
                    </Stack>
                </div>
                <div id="orte">
                    <Stack tokens={{ childrenGap: 60 }} horizontalAlign="center">
                        <Text styles={cursiveStyle} variant={'xxLarge'} style={{ textAlign: 'center' }}>
                            {'Orte'}
                        </Text>
                        <Stack horizontal={!isMobile} tokens={{ childrenGap: 80 }} styles={{ inner: { width: 'unset', height: '100%' } }} wrap horizontalAlign="center">
                            <BaseTile
                                isMobile={isMobile}
                                style={{ cursor: 'pointer', width: 370, outlineStyle: 'solid', outlineColor: '#EBFFC1', outlineWidth: mapCenter === ceremonyPos ? 1 : 0 }}
                                onClick={() => {
                                    setMapCenter(ceremonyPos);
                                    setMapZoom(14);
                                }}
                            >
                                <Stack horizontalAlign="center">
                                    <Text variant={'large'}>{'Standesamt Magdeburg'}</Text>
                                    <img src="./standesamt.png" alt="Standesamt" height={270} style={{ objectFit: 'contain', objectPosition: '0px', marginBottom: 30 }} />
                                    <img src="./map_marker1.svg" alt="Marker" style={{ marginTop: -47, marginBottom: 15 }} />
                                    <Text variant={'medium'}>{'Humboldtstraße 11'}</Text>
                                    <Text variant={'medium'}>{'39112 Magdeburg'}</Text>
                                </Stack>
                            </BaseTile>
                            <BaseTile
                                isMobile={isMobile}
                                style={{
                                    cursor: 'pointer',
                                    width: 370,
                                    outlineStyle: 'solid',
                                    outlineColor: theme.palette.themePrimary,
                                    outlineWidth: mapCenter === partyPos ? 1 : 0,
                                }}
                                onClick={() => {
                                    setMapCenter(partyPos);
                                    setMapZoom(14);
                                }}
                            >
                                <Stack horizontalAlign="center">
                                    <Text style={{ textAlign: 'center' }} variant={'large'}>
                                        {'Orangerie im Elbauenpark'}
                                    </Text>
                                    <img src="./orangerie_2.png" alt="Orangerie" height={300} style={{ objectFit: 'contain', objectPosition: '-14px -20px' }} />
                                    <img src="./map_marker2.svg" alt="Marker" style={{ marginTop: -47, marginBottom: 15 }} />
                                    <Text variant={'medium'}>{'Herrenkrugstraße 140'}</Text>
                                    <Text variant={'medium'}>{'39114 Magdeburg'}</Text>
                                </Stack>
                            </BaseTile>
                        </Stack>
                        <GoogleMap
                            mapContainerStyle={{ width: pageWidth ?? '100%', height: 400, overflow: 'hidden' }}
                            clickableIcons={false}
                            options={{
                                disableDefaultUI: true,
                                disableDoubleClickZoom: true,
                                scaleControl: false,
                                panControl: false,
                                streetViewControl: false,
                                zoomControl: true,
                                rotateControl: false,
                                zoom: mapZoom,
                                minZoom: 10,
                                maxZoom: 15,
                                backgroundColor: theme.palette.white,
                                styles: darkStyles,
                                center: { lat: mapCenter.lat, lng: mapCenter.lng },
                            }}
                            onLoad={onLoad}
                        >
                            <Marker
                                icon={{
                                    url: './map_marker2.svg',
                                }}
                                onClick={() => {
                                    setMapCenter(partyPos);
                                    setMapZoom(14);
                                }}
                                position={partyPos}
                            ></Marker>
                            <Marker
                                icon={{
                                    url: './map_marker1.svg',
                                }}
                                onClick={() => {
                                    setMapCenter(ceremonyPos);
                                    setMapZoom(14);
                                }}
                                clickable={true}
                                position={ceremonyPos}
                            ></Marker>
                        </GoogleMap>
                    </Stack>
                </div>
                <div id="unterkuenfte">
                    <Stack tokens={{ childrenGap: 60 }}>
                        <Stack horizontalAlign="center">
                            <Text styles={cursiveStyle} variant={'xxLarge'}>
                                {'Unterkunft'}
                            </Text>
                            <Text variant={'mediumPlus'}>{'Unsere Empfehlungen:'}</Text>
                        </Stack>
                        <BaseTile isMobile={isMobile}>
                            <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 20 }} wrap>
                                <img src="./stadtfeld.png" alt="Stadtfeld" width={isMobile ? 300 : 200} style={{ objectFit: 'contain' }} />
                                <Stack tokens={minimalSpacingToken} style={{ minWidth: 300, borderRadius: 4 }}>
                                    <Text variant={'mediumPlus'}>{'Hotel Stadtfeld'}</Text>
                                    <Text variant={'medium'}>{'Distanz: 23km'}</Text>
                                    <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 30 }}>
                                        <Text variant={'medium'}>{'Preis: €€'}</Text>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://www.booking.com/Share-PIT2H2">
                                            <Text variant={'small'} style={{ color: linkColor }}>
                                                {'Booking.com'}
                                            </Text>
                                        </a>
                                    </Stack>
                                    <Stack horizontal verticalAlign="end" horizontalAlign="space-between" tokens={{ childrenGap: 30 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text variant={'small'}>{'Maxim Gorki Str. 31/37'}</Text>
                                            <Text variant={'small'}>{'39108 Magdeburg'}</Text>
                                        </div>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://goo.gl/maps/xK3N1jVzUJ9Ltyq1A">
                                            <Text variant={'small'} style={{ color: linkColor }}>
                                                {'Maps'}
                                            </Text>
                                        </a>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </BaseTile>
                        <BaseTile isMobile={isMobile}>
                            <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 20 }} wrap>
                                <img src="./herrenkrug.png" alt="Herrenkrug" width={isMobile ? 300 : 200} style={{ objectFit: 'contain' }} />
                                <Stack tokens={minimalSpacingToken} style={{ minWidth: isMobile ? 300 : 315, borderRadius: 4 }}>
                                    <Text variant={'mediumPlus'}>{'Dorint Herrenkrug Parkhotel'}</Text>
                                    <Text variant={'medium'}>{'Distanz: 12km'}</Text>
                                    <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 30 }}>
                                        <Text variant={'medium'}>{'Preis: €€€'}</Text>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://www.booking.com/Share-vaSzSm9">
                                            <Text variant={'small'} style={{ color: linkColor }}>
                                                {'Booking.com'}
                                            </Text>
                                        </a>
                                    </Stack>
                                    <Stack horizontal verticalAlign="end" horizontalAlign="space-between" tokens={{ childrenGap: 30 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text variant={'small'}>{'Herrenkrug 3'}</Text>
                                            <Text variant={'small'}>{'39114 Magdeburg'}</Text>
                                        </div>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://goo.gl/maps/pJzwG8QRXGNB16NR6">
                                            <Text variant={'small'} style={{ color: linkColor }}>
                                                {'Maps'}
                                            </Text>
                                        </a>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </BaseTile>
                    </Stack>
                </div>
                <Stack tokens={{ childrenGap: 60 }}>
                    <Text styles={cursiveStyle} variant={'xxLarge'}>
                        {'Unsere Wünsche'}
                    </Text>
                    <RulesTile style={{ marginLeft: isMobile ? 0 : 90, marginRight: isMobile ? 0 : 90 }}>
                        <Stack tokens={{ childrenGap: 80 }} style={{ marginLeft: isMobile ? 0 : 70, marginRight: isMobile ? 0 : 70, marginTop: 20, marginBottom: 40 }}>
                            <Stack horizontalAlign="center">
                                <Text styles={cursiveStyle} variant={'xLarge'}>
                                    {'Dresscode'}
                                </Text>
                                <Text variant={'mediumPlus'}>{'Summer Chic. Sommerlich, aber dennoch festlich.'}</Text>
                                <Text variant={'medium'} style={{ textAlign: 'justify' }}>
                                    {'Kleidet euch sommerlich und festlich, es darf (und soll) getanzt werden. Jeans und Turnschuhe dürfen dennoch zu Hause bleiben.'}
                                </Text>
                            </Stack>
                            <Stack horizontalAlign="center">
                                <Text styles={cursiveStyle} variant={'xLarge'}>
                                    {'Good to know'}
                                </Text>
                                <Text variant={'medium'} style={{ textAlign: 'justify' }}>
                                    {`Weiß auf der Hochzeit trägt nur die Braut.
                         Auch die Farben Rot und Schwarz solltet Ihr vermeiden.
                          Die Signalfarbe Rot ist zu dramatisch.
                           Außerdem wird der Farbe nachgesagt,
                            dass die Trägerin ein intimes Verhältnis mit dem Bräutigam hatte. 
                            Auch Schwarz als Farbe der Trauer ist zu diesem fröhlichen Anlass nicht passend.`}
                                </Text>
                            </Stack>
                            <Stack horizontalAlign="center">
                                <Text styles={cursiveStyle} variant={'xLarge'}>
                                    {'Digital Detox'}
                                </Text>
                                <Text variant={'medium'} style={{ textAlign: 'justify' }}>
                                    {`Auf einer Hochzeit bzw. mindestens während der Trauung gilt: 
                        Handy aus (oder Flugmodus an)! Handyklingeln, Nachrichtentöne 
                        und Vibrationsgeräusche stören die Zeremonie und sind hier wirklich fehl am Platz. 
Wir engagieren zudem einen professionellen Fotografen für unsere Hochzeit, deren Job es ist, 
die schönsten Momente an unserem Tag festzuhalten. Du kannst dich also getrost zurücklehnen.`}
                                </Text>
                            </Stack>
                            <Stack horizontalAlign="center">
                                <Text styles={cursiveStyle} variant={'xLarge'}>
                                    {'Adults only'}
                                </Text>
                                <Text variant={'medium'} style={{ textAlign: 'justify' }}>
                                    {`Auf unserer Hochzeit gilt Adults Only. Wir möchten Euch bei unserer
                         Hochzeit ganz für uns haben und bitten Euch daher, eure Kids ausnahmsweise
                          zu Hause zu lassen.`}
                                </Text>
                            </Stack>
                            <img src="./flower_3.svg" alt="Flower3" height={isMobile ? 100 : 200} style={{ objectFit: 'contain' }} />
                        </Stack>
                    </RulesTile>
                </Stack>
                <div id="ansprechpartner">
                    <Stack tokens={{ childrenGap: 60 }} horizontalAlign="center">
                        <Stack>
                            <Text styles={cursiveStyle} variant={'xxLarge'} style={{ textAlign: 'center' }}>
                                {'Kontakt'}
                            </Text>
                            <Text variant={'mediumPlus'} style={{ textAlign: 'center' }}>
                                {'Fragen? Ideen? Wünsche?'}
                            </Text>
                            <Text variant={'mediumPlus'} style={{ textAlign: 'center' }}>
                                {'Unsere Trauzeugen helfen gerne weiter:'}
                            </Text>
                        </Stack>
                        <Stack tokens={{ childrenGap: 80 }} styles={{ inner: { width: 'unset', height: '100%' } }} wrap horizontalAlign="center" horizontal={!isMobile}>
                            <BaseTile isMobile={isMobile}>
                                <Stack tokens={minimalSpacingToken} horizontalAlign="center">
                                    <img
                                        src="./daniel.png"
                                        alt="Daniel"
                                        height={300}
                                        style={{ marginBottom: 17, marginRight: 17, objectFit: 'contain', objectPosition: '-0x -0px' }}
                                    />
                                    <Text variant={'medium'}>{'Daniel Wiemann'}</Text>
                                    <a style={{ textDecoration: 'none' }} href={`tel:${props.invitationData.weddingInformation.telDaniel}`}>
                                        <Text variant={'medium'} style={{ color: linkColor }}>
                                            {props.invitationData.weddingInformation.telDanielDisplay}
                                        </Text>
                                    </a>
                                </Stack>
                            </BaseTile>
                            <BaseTile isMobile={isMobile}>
                                <Stack tokens={minimalSpacingToken} horizontalAlign="center">
                                    <img
                                        src="./elisa.png"
                                        alt="Elisa"
                                        height={300}
                                        style={{ marginBottom: 17, objectFit: 'contain', objectPosition: '0px 0px', transform: 'scale(0.82) translate(0, 32px)' }}
                                    />
                                    <Text variant={'medium'}>{'Elisa Däter'}</Text>
                                    <a style={{ textDecoration: 'none' }} href={`tel:${props.invitationData.weddingInformation.telElisa}`}>
                                        <Text variant={'medium'} style={{ color: linkColor }}>
                                            {props.invitationData.weddingInformation.telElisaDisplay}
                                        </Text>
                                    </a>
                                </Stack>
                            </BaseTile>
                        </Stack>
                    </Stack>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div id="teilnehmer">
                        <Stack
                            tokens={{ childrenGap: 60 }}
                            horizontalAlign="center"
                            style={{ outlineColor: '#a6a6a6', outlineStyle: 'solid', outlineWidth: isMobile ? 0 : 1, padding: isMobile ? 0 : 64, borderRadius: 4 }}
                        >
                            <Text styles={cursiveStyle} variant={'xxLarge'}>{`Begleitung`}</Text>
                            <Text variant={'mediumPlus'} style={{ textAlign: 'center' }}>
                                {isAssignedMultiple ? 'Bitte gebt uns bis zum 15.06.2023 Bescheid, ob Ihr kommt.' : 'Bitte gib uns bis zum 15.06.2023 Bescheid, ob Du kommst.'}
                            </Text>
                            <Stack tokens={minimalSpacingToken}>
                                <GuestList
                                    accompanimentNumber={props.invitationData.invitation.accompanimentNumber}
                                    guests={guests}
                                    onChange={(newGuests) => setGuests(newGuests)}
                                />
                            </Stack>
                            {(props.invitationData.invitation.address == null ||
                                props.invitationData.invitation.address.city === '' ||
                                props.invitationData.invitation.address.line1 === '' ||
                                props.invitationData.invitation.address.postalCode === '') && (
                                <Stack tokens={{ childrenGap: 60 }} horizontalAlign="center">
                                    <Stack horizontalAlign="center">
                                        <Text variant={'medium'} style={{ textAlign: 'center' }}>
                                            {isAssignedMultiple ? 'Tragt hier eure Adresse ein,' : 'Trage hier Deine Adresse ein,'}
                                        </Text>
                                        <Text variant={'medium'} style={{ textAlign: 'center' }}>
                                            {isAssignedMultiple
                                                ? 'damit wir uns im Anschluss postalisch bei Euch bedanken können:'
                                                : 'damit wir uns im Anschluss postalisch bei Dir bedanken können:'}
                                        </Text>
                                    </Stack>
                                    <RulesTile>
                                        <Stack tokens={minimalSpacingToken}>
                                            <TextField disabled={isBusy} label="Straße & Hausnummer" value={line1} onChange={(_, newValue) => setLine1(newValue || '')} />
                                            <Stack tokens={minimalSpacingToken} horizontal>
                                                <TextField disabled={isBusy} label="PLZ" value={postalCode} onChange={(_, newValue) => setPostalCode(newValue || '')} />
                                                <TextField disabled={isBusy} label="Stadt" value={city} onChange={(_, newValue) => setCity(newValue || '')} />
                                            </Stack>
                                        </Stack>
                                    </RulesTile>
                                </Stack>
                            )}
                            <PrimaryButton style={{ minWidth: 300, minHeight: 50 }} disabled={isBusy || !hasChanges} text="Speichern" onClick={() => answerInvitation()} />
                        </Stack>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 35, color: 'red !important' }}>
                        <AddToCalendarButton
                            name="Hochzeit von Michelle & Jens"
                            options={['Google', 'Apple', 'iCal']}
                            description={`Hier kommst du zu deiner [url]${window.location.href}|Einladung[/url]`}
                            location="Landeshauptstadt Magdeburg Standesamt, Humboldtstraße 11, 39112 Magdeburg, Deutschland"
                            startDate="2023-07-21"
                            endDate="2023-07-21"
                            startTime="13:45"
                            endTime="23:45"
                            timeZone="Europe/Berlin"
                            availability="busy"
                            hideCheckmark
                            lightMode="dark"
                            language="de"
                            label="Zum Kalender hinzufügen"
                        ></AddToCalendarButton>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="./flower_2.svg" alt="Flower2" width={isMobile ? 300 : 400} style={{ objectFit: 'contain', marginTop: 60 }} />
                    </div>
                </div>
            </Stack>
        </div>
    );
};
