import { Checkbox, DefaultButton, IButtonStyles, IStackTokens, Stack, Text, TextField, useTheme } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Guest } from '../types/Guest';
import useIsMobile from './useIsMobile';

const Tile = styled.div`
    background-color: #262626;
    border-radius: 7px;
    padding: 15px;
`;

export interface props {
    guest: Guest;

    onChange: (guest: Guest) => void;

    delete: (guest: Guest) => void;
}

export const GuestComponent: FunctionComponent<props> = (props: props): ReactElement => {
    /**
     * Global fluent UI theme.
     */
    const theme = useTheme();

    const minimalSpacingToken: IStackTokens = {
        childrenGap: 5,
    };

    const [firstName, setFirstName] = useState<string>(props.guest.firstName ?? '');
    const [surName, setSurName] = useState<string>(props.guest.surName ?? '');
    const [eatsFish, setEatsFish] = useState(props.guest.eatsFish ?? false);
    const [eatsMeat, setEatsMeat] = useState(props.guest.eatsMeat ?? false);
    const [attendsCeremony, setAttendsCeremony] = useState(props.guest.willAttendCeremony ?? false);
    const [attendsParty, setAttendsParty] = useState(props.guest.willAttendParty ?? false);

    useEffect(() => {
        const newGuest: Guest = {
            firstName: firstName,
            surName: surName,
            eatsFish: eatsFish,
            eatsMeat: eatsMeat,
            id: props.guest.id,
            willAttendCeremony: attendsCeremony,
            willAttendParty: attendsParty,
            isInvitationHolder: props.guest.isInvitationHolder,
        };

        const hasChanges =
            newGuest.firstName !== (props.guest.firstName ?? '') ||
            newGuest.surName !== (props.guest.surName ?? '') ||
            newGuest.eatsFish !== (props.guest.eatsFish ?? false) ||
            newGuest.eatsMeat !== (props.guest.eatsMeat ?? false) ||
            newGuest.willAttendCeremony !== (props.guest.willAttendCeremony ?? false) ||
            newGuest.willAttendParty !== (props.guest.willAttendParty ?? false);

        if (hasChanges) {
            props.onChange(newGuest);
        }
    }, [attendsCeremony, attendsParty, eatsFish, eatsMeat, firstName, props, props.guest.id, surName]);

    const isDisabled = props.guest && props.guest.isInvitationHolder === true;

    const removeButtonStyle: IButtonStyles = {
        root: {
            margin: 'none',
            minWidth: 20,
            minHeight: 20,
            height: 20,
            width: 20,
            padding: 0,
        },
        rootDisabled: {
            visibility: 'hidden',
        },
        label: {
            fontWeight: 500,
            fontSize: 14,
        },
        labelDisabled: {
            color: theme.palette.neutralLighterAlt,
        },
    };

    const isMobile = useIsMobile();

    return (
        <Tile>
            <Stack tokens={{ childrenGap: 20 }}>
                <Stack>
                    <Stack horizontal horizontalAlign="end">
                        <DefaultButton
                            style={{ display: isDisabled ? 'none' : 'block' }}
                            styles={removeButtonStyle}
                            disabled={isDisabled}
                            text="X"
                            onClick={() => props.delete(props.guest)}
                        />
                    </Stack>
                    <Stack tokens={minimalSpacingToken} horizontal>
                        <TextField disabled={isDisabled} label="Vorname" value={firstName} onChange={(_, newValue) => setFirstName(newValue || '')} />
                        <TextField disabled={isDisabled} label="Nachname" value={surName} onChange={(_, newValue) => setSurName(newValue || '')} />
                    </Stack>
                </Stack>
                <Stack tokens={minimalSpacingToken} style={{ marginRight: isMobile ? 0 : 64, paddingBottom: 16 }} horizontal horizontalAlign="space-between">
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Text variant={'medium'} style={{ marginBottom: 10 }}>
                            {'Ich nehme Teil an:'}
                        </Text>
                        <Checkbox label="Standesamt" checked={attendsCeremony} onChange={(_, checked?: boolean): void => setAttendsCeremony(!!checked)} />
                        <Checkbox label="Party" checked={attendsParty} onChange={(_, checked?: boolean): void => setAttendsParty(!!checked)} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Text variant={'medium'} style={{ marginBottom: 10 }}>
                            {'Ich esse:'}
                        </Text>
                        <Checkbox label="Fleisch" checked={eatsMeat} onChange={(_, checked?: boolean): void => setEatsMeat(!!checked)} />
                        <Checkbox label="Fisch" checked={eatsFish} onChange={(_, checked?: boolean): void => setEatsFish(!!checked)} />
                    </Stack>
                </Stack>
            </Stack>
        </Tile>
    );
};
