import { IStackTokens, PrimaryButton, Stack } from '@fluentui/react';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';

import { Guest } from '../types/Guest';
import { GuestComponent } from './GuestComponent';

export interface props {
    guests?: Guest[];

    onChange: (guests: Guest[]) => void;

    accompanimentNumber: number;
}

export const GuestList: FunctionComponent<props> = (props: props): ReactElement => {
    /**
     * Basic stack layout tokens.
     */
    const stackTokens: IStackTokens = {
        childrenGap: 50,
    };

    const [guests, setGuests] = useState<Guest[]>(props.guests ?? []);

    const guestsLeft = props.accompanimentNumber - guests.length;

    useEffect(() => {
        props.onChange(guests);
    }, [guests, props]);

    const onChangeGuest = (guest: Guest): void => {
        if (guest == null) {
            return;
        }
        const newGuestList: Guest[] = [...guests.filter((g) => g.id !== guest.id)];
        newGuestList.push(guest);
        setGuests(newGuestList);
    };

    const addNewGuest = (): void => {
        const newGuest: Guest = {
            firstName: '',
            surName: '',
            eatsFish: false,
            eatsMeat: false,
            id: Date.now(),
            isInvitationHolder: false,
            willAttendCeremony: false,
            willAttendParty: false,
        };
        onChangeGuest(newGuest);
    };

    const deleteGuest = (guest: Guest): void => {
        setGuests((prev) => [...prev.filter((g) => g.id !== guest.id)]);
    };

    return (
        <Stack tokens={stackTokens} style={{ width: '100%' }} verticalAlign="center" horizontalAlign="center">
            {guests
                .sort((a, b) => a.id - b.id)
                .map((g) => (
                    <GuestComponent delete={deleteGuest} guest={g} key={g.id} onChange={onChangeGuest} />
                ))}
            {props.accompanimentNumber > guests.length && (
                <PrimaryButton text={guestsLeft <= 1 ? 'Begleitung hinzufügen' : `Begleitung hinzufügen (${guestsLeft})`} onClick={addNewGuest} />
            )}
        </Stack>
    );
};
