import { initializeIcons } from '@fluentui/react';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { Store } from './store/Store';

// Initialize the fluent ui icons.
initializeIcons();
// Get the root element.
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
// Wrap the application with a store provider and render the application at the root element.
root.render(
    /* @ts-expect-error: Ignore no children prop error. */
    <StoreProvider store={Store}>
        <App />
    </StoreProvider>
);
