import { useEffect, useState } from 'react';

/**
 * Hook to determine if the current device is a mobile device.
 *
 * @returns {boolean} Whether the current device is a mobile device or not.
 */
export default function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 767) {
                // 621 ?
                setIsMobile(false);
            } else if (window.innerWidth < 767) {
                setIsMobile(true);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
}
